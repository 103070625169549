<template>
	<div class="prepaid-recharge">
		<img class="recharge-tips" src="@/assets/task/recharge-tips.png" alt="" srcset="" />
		<div class="tips">我的补给箱</div>
		<!-- 价格100，500，1000，2000，5000，10000就行 -->
		<div class="recharge">
			<div class="recharge-item">
				<div>充值金额</div>
				<div>首次补给</div>
			</div>
			<div class="recharge-item item" v-for="(item, index) in list" :key="index">
				<div style="font-weight: bold">{{ item.upgrade }}</div>
				<div @click="getChongzhils(index + 1)">
					<img src="@/assets/task/recharge.png" alt="" srcset="" />
				</div>
			</div>
		</div>
		<div class="win-box" v-if="money">
			<div class="win">
				<div class="win-conm"
					style="color: white;display: flex;align-items: center;flex-flow: row;justify-content: center;">
					<div class="win-conm">
						<div class="win-tips">恭喜获得</div>
					</div>
					<div>{{money}}</div><img src="@/assets/img/money.png" style="width: 24px;">
				</div>
				<div class="win-span2" @click="money = null">放入背包</div>
			</div>
		</div>
	</div>
</template>

<script>
	export default {
		data() {
			return {
				list: [],
				money: null,
			};
		},
		methods: {
			async getChongzhils(id) {
				const params = {
					player_id: localStorage.getItem("id"),
					id,
				};
				const {
					data
				} = await this.$axios.post(
					"/index/Activity/czls",
					this.$qs.stringify(params)
				);

				if (data.status) {
					this.money = data.data;
					this.$parent.$parent.getPlayerInfo();
					this.$message.success("领取成功");
				} else {
					this.$message.error(data.msg);
				}


			},
			getrecharge(){
			this.$axios.get("/index/Activity/czls_info",).then(res=>{
				console.log(res.data.data)
				if(res.status==200){
					this.list=res.data.data	
				}else{
					this.$message.error("暂时还没有充值记录");
				}
			})
			},
		},
		mounted(){
	  this.getrecharge()
    },
	};
</script>

<style lang="less" scoped>
	.win {

		width: 450px;
		padding: 20px;
		background-color: #1c2230;
		position: relative;
		box-sizing: border-box;
		border-radius: 10px;

		.win-span2 {
			padding: 10px 0;
			height: 20px;
			background-color: #e9b10e;
			background-size: 100% 100%;
			border: 1px solid #ffea95;
			color: #000000;
			display: flex;
			font-weight: bold;
			justify-content: center;
			align-items: center;
			border-radius: 5px;
			margin-top: 15px;
		}

		.win-span2:hover {
			cursor: pointer;
		}
	}

	//中奖后盒子
	.win-box {
		position: fixed;
		z-index: 99999;
		top: 0;
		left: 0;
		height: 100vh;
		width: 100vw;
		background-color: rgba(0, 0, 0, 0.7);
		display: grid;
		place-items: center;
	}

	.prepaid-recharge {
		color: #fff;
		text-align: center;

		.tips {
			font-size: 22px;
			margin-top: 20px;
		}

		.recharge-tips {
			width: 95%;
			max-width: 400px;
		}

		.recharge {
			max-width: 600px;
			margin: 0 auto;
			margin-top: 30px;

			&-item:first-child {
				margin-bottom: 20px;
			}

			&-item {
				display: flex;
				align-items: center;
				width: 100%;

				&>div {
					flex: 1;
					font-size: 14px;

					img {
						width: 30%;
						max-width: 100px;
					}
				}
			}
		}
	}
.item{
	border: 1px solid #797a7a;
	background-color: #63686c;
}
</style>