<template>
	<div class="task-center">
		<myinform></myinform>
		<!-- <myslide></myslide> -->
		<!-- my task center -->
		<!-- <LrfTabs :active.sync="activeName" :list="tabs" /> -->

		<!-- <keep-alive>
			<transition name="el-zoom-in-center">
				<Component :is="activeName"></Component>
			</transition>
		</keep-alive> -->
		<keep-alive>
			<SignDay></SignDay>
		</keep-alive>
	</div>
</template>

<script>
	import myslide from "@/components/my_slide1.vue";
	import myinform from "@/components/my_inform.vue";
	import {
		SignDay,
		BindBoxTask,
		CombatTask,
		PrepaidRecharge,
		PasswordPacket,
		LrfTabs,
	} from "./components";
	export default {
		components: {
			myslide,
			myinform,
			SignDay,
			BindBoxTask,
			CombatTask,
			PrepaidRecharge,
			PasswordPacket,
			LrfTabs,
		},
		data() {
			return {
				activeName: "SignDay",
				tabs: [{
						componentName: "SignDay",
						name: "每日签到",
					},
					{
						componentName: "BindBoxTask",
						name: "宝箱任务",
					},
					{
						componentName: "CombatTask",
						name: "对战任务",
					},
					{
						componentName: "PrepaidRecharge",
						name: "充值补给",
					},
					// {
					// 	componentName: "PasswordPacket",
					// 	name: "口令红包",
					// },
				],
			};
		},
		mounted() {
			if (this.$route.query.type == '盲盒福利') {
				this.activeName = 'BindBoxTask';
			}
			if (this.$route.query.type == '对战福利') {
				this.activeName = 'CombatTask';
			}
			if (this.$route.query.type == '充值福利') {
				this.activeName = 'PrepaidRecharge';
			}
		}
	};
</script>

<style lang="less" scoped src="./task-center.less"></style>